






















import { Vue, Component } from "vue-property-decorator";

@Component({
  name: "Reset",
})
export default class extends Vue {}
